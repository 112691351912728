import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyResponse } from '../../services/companyService';
import { User } from '../../services/usersService';
import { SingleEditPanelRoute } from '../manageUsers';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

type SubNav = {
  title: string;
  path: string;
};

type AuthType = 'trial' | 'user' | null;

type state = {
  subNav: Array<SubNav>;
  authed: AuthType;
  userToken?: string;
  userEmail?: string;
  userFeatures: Array<string>;
  user: StateUser;
  userCompanyData?: CompanyResponse;
  responseScreens?: {
    successMessage?: string;
    closePanelOnSuccess?: boolean;
    closePanelOnError?: boolean;
    onErrorCloseRoute?: {
      route: SingleEditPanelRoute;
      payload?: any;
    };
  };
  formHasChanges: boolean;
  getAccessToken?: () => Promise<string>;
  auth0LoginRedirect?: () => Promise<void>;
};

type StateUser = {
  permissions: string[];
} & Omit<User, 'permissions'>;

const initialState: state = {
  subNav: [],
  authed: null,
  user: {
    _id: '',
    email: '',
    phone: '',
    permissions: [''],
    company_id: '',
    metaData: {
      unlimitedDevices: false,
    },
    company: '',
    firstname: '',
    lastname: '',
    createdOn: '',
    roles: [''],
  },
  userFeatures: [],
  formHasChanges: false,
};

export const {
  actions: {
    setSubNav,
    setAuthed,
    setUserToken,
    setUserEmail,
    setUser,
    setUserFeatures,
    setUserCompanyData,
    setFormHasChanges,
    setGetAccessToken,
    setAuth0LoginRedirect,
  },
  reducer: adminShellReducer,
} = createSlice({
  name: 'adminShell',
  initialState,
  reducers: {
    setSubNav(state, { payload }: PayloadAction<Array<SubNav>>) {
      state.subNav = payload;
    },
    setAuthed(state, { payload }: PayloadAction<AuthType>) {
      state.authed = payload;
    },
    setUserToken(state, { payload }: PayloadAction<string>) {
      state.userToken = payload;
    },
    setUserEmail(state, { payload }: PayloadAction<string>) {
      state.userEmail = payload;
    },
    setUser(state, { payload }: PayloadAction<StateUser>) {
      state.user = payload;
      LocalAuth.setUser(payload);
    },
    setUserFeatures(state, { payload }: PayloadAction<Array<string>>) {
      state.userFeatures = payload;
    },
    setUserCompanyData(state, { payload }: PayloadAction<CompanyResponse>) {
      state.userCompanyData = payload;
    },
    setResponseScreenData(state, { payload }: PayloadAction<typeof state.responseScreens>) {
      state.responseScreens = payload;
    },
    setFormHasChanges(state, { payload }: PayloadAction<boolean>) {
      state.formHasChanges = payload;
    },
    setGetAccessToken(state, { payload }: PayloadAction<() => Promise<string>>) {
      state.getAccessToken = payload;
    },
    setAuth0LoginRedirect(state, { payload }: PayloadAction<() => Promise<void>>) {
      state.auth0LoginRedirect = payload;
    },
  },
});
